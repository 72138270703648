<template>
  <div>
    <!-- <v-app-bar app color="red darken-4" dark dense>
      <v-app-bar-nav-icon @click.stop="$emit('drawerControl')"></v-app-bar-nav-icon>
      <v-toolbar-title>Document Uploads</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="action"></slot>
    </v-app-bar> -->

    <v-container>
      <page-title title="Document Uploads"></page-title>
      <v-row>
        <v-col md="3">
          <v-card>
            <v-card-title> <v-icon>mdi-folder</v-icon>&nbsp;&nbsp;<span>Folders</span> </v-card-title>
            <v-divider></v-divider>
            <v-list two-line>
              <v-list-item-group v-model="selectedFolderItem" color="primary">
                <v-list-item v-for="item in folderItems" :key="item.folderName" link @click="getDatatableItems(item.folderPath)">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.folderName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.folderPath }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col md="9">
          <v-card :loading="datatable.loading">
            <v-card-title v-if="selectedFolder != ''">
              <v-btn @click="$refs.refFileUpload.click()" v-if="selectedFolder != ''" color="primary" icon>
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <v-btn @click="getDatatableItems(selectedFolder)" v-if="selectedFolder != ''" color="primary" icon>
                <v-icon>mdi-sync</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text v-if="selectedFolder == ''"> Select Folder </v-card-text>
            <v-data-table v-if="selectedFolder != ''" dense :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.headers" :items="datatable.items" :search="search">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="deleteItem(item)" color="error"> mdi-delete </v-icon>
                <v-icon small @click="downloadItem(item)" color="success"> mdi-download </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <input type="file" class="d-none" id="fileupload" name="fileupload" ref="refFileUpload" @change="handleFileUpload" multiple />
  </div>
</template>

<script>
export default {
  data: () => ({
    folderItems: [
      {
        folderName: "Delivery",
        folderPath: "delivery",
        folderInfo: "Delivery",
      },
      {
        folderName: "Invoice",
        folderPath: "invoice",
        folderInfo: "Invoice",
      },
      {
        folderName: "Payment",
        folderPath: "payment",
        folderInfo: "Payment",
      },
    ],
    search: "",
    datatable: {
      loading: false,
      loadingText: "Loading... Please wait...",
      headers: [
        { text: "#", value: "no" },
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Size", value: "size" },
        { text: "Actions", value: "actions" },
      ],
      items: [],
    },
    selectedFolderItem: { folderName: "Delivery", folderPath: "delivery", folderInfo: "Delivery" },
    selectedFolder: "",
    fileupload: null,
  }),

  methods: {
    getDatatableItems(folderPath) {
      this.datatable.loading = true;
      this.datatable.items = [];

      this.selectedFolder = folderPath;

      var formData = new FormData();
      formData.append("folder", folderPath);

      this.$axios
        .post("documentuploads/browse", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.items = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    handleFileUpload() {
      if (!confirm("Upload File?")) return;

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("folder", this.selectedFolder);

      for (var i = 0; i < this.$refs.refFileUpload.files.length; i++) {
        let file = this.$refs.refFileUpload.files[i];
        formData.append("fileupload[" + i + "]", file);
      }

      this.$axios
        .post("documentuploads/uploads", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.getDatatableItems(this.selectedFolder);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    deleteItem(item) {
      if (!confirm('Delete "' + item.name + '"?')) return;

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("folder", this.selectedFolder);
      formData.append("file", item.name);
      this.$axios
        .post("documentuploads/delete", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.getDatatableItems(this.selectedFolder);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.showLoadingOverlay(false);
        });
    },

    downloadItem(item) {
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("folder", this.selectedFolder);
      formData.append("file", item.name);
      this.$axios
        .post("documentuploads/download", formData, { responseType: "blob" })
        .then((res) => {
          this.showLoadingOverlay(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', item.name); //or any other extension
          link.setAttribute('target', '_blank'); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.showLoadingOverlay(false);
        });
    },
  },
};
</script>